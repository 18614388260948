import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {
      isAccepted: false
    },
    account: {
      id: 0,
      name: '',
      logo: '',
      billing: 0,
      status: 0
    },
    station: {
      stationData: {
        name: '',
        city: '',
        state: '',
        img: '',
        radioChecked: 0,
        fm: {
          frecuencia: '',
          cobertura: '',
          siglas: ''
        },
        am: {
          frecuencia: '',
          cobertura: '',
          siglas: ''
        },
        styleMusic: [],
        description: '',
        idStation: 0
      },
      listUsers: [],
      audience: {
        gender: {
          isMale: false,
          isFemale: false
        },
        age: [0, 0, 0, 0, 0, 0, 0, 0],
        socioeconomic: {
          ab: false,
          c: false,
          d: false
        }
      },
      dataTarifa: [],
      dataTarifa2: 0
    },
    userAccount: {
      data: {
        name: '',
        img: '',
        bu: 0
      },
      listUsers: []
    },
    userAdvertiser: {
      data: {
        name: '',
        img: ''
      },
      listUsers: []
    },
    campaign: {
      dataCampaign: {
        campaignId: null,
        advertiser: 0,
        name: '',
        timer: null,
        start: null,
        ending: null,
        location: [],
        state: [],
        estimate: '',
        number: ''
      },
      rangeDays: {
        range: 1,
        rangeName: 'Lunes a Viernes',
        schedule: ''
      },
      audienceCampaign: {
        socioeconomicNames: [],
        gender: {
          isMale: false,
          isFemale: false
        },
        age: [],
        socioeconomic: [],
        ageAcronym: []
      },
      audience: {
        gender: 0,
        year: 0,
        socioeconomic: 0,
        genderMusical: [],
        moreGenderMusicals: false
      },
      uploadMp3: {
        name: '',
        mp3ListSend: [],
        listMp3: [],
        totalSum: 0,
        personalizationSpots: false,
        listSpotsCity: []
        // listSpotsCity: [{ id: 1, name: 'Acapulco', audios: [{ id: 'audio3', name: '008576979_prev.mp3', audio: '', value: 33.3 }, { id: 'audio21', name: 'himno-argentino-cort.mp3', audio: '', value: 33.3 }, { id: 'audio17', name: 'eye-tiger.mp3', audio: '', value: 33.3 }] }, { id: 2, name: 'Aguascalientes', audios: [{ id: 'audio3', name: '008576979_prev.mp3', audio: '', value: 33.3 }, { id: 'audio21', name: 'himno-argentino-cort.mp3', audio: '', value: 33.3 }, { id: 'audio17', name: 'eye-tiger.mp3', audio: '', value: 33.3 }] }]
      },
      selectedStation: {
        cpm: 0,
        listStation: []
      }
    },
    billing: {
      id: 0,
      idList: 0
    },
    billignDeatail: {
      id: '',
      name: '',
      razonsocial: '',
      sector: '',
      rfc: '',
      regimentFiscal: '',
      address: '',
      country: '',
      city: '',
      state: '',
      cp: '',
      nameBank: '',
      numberAccount: '',
      clabe: '',
      emailFactura: ''
    }
  },
  getters: {
  },
  mutations: {
    setAccepted (state) {
      state.user.isAccepted = true
    },
    addEmisora (state, value) {
      state.station.stationData = value
    },
    addUsers (state, value) {
      state.station.listUsers = value
    },
    addAudience (state, value) {
      state.station.audience = value
    },
    addTarifas (state, value) {
      state.station.dataTarifa = value
    },
    addAdvertiser (state, value) {
      state.userAdvertiser.data = value
    },
    addAccount (state, value) {
      state.userAccount.data = value
    }
  },
  actions: {
  },
  modules: {
  }
})
