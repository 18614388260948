import axios from 'axios'
import { dataEnv } from '../../config/index.js'

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const setUserAdvertiser = (payload) => {
  return axios.post(`${dataEnv().API_URL}/register/user`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const sendTokenAccess = (payload) => {
  return axios.post(`${dataEnv().API_URL}/resendmail`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const setUserClient = (payload) => {
  return axios.post(`${dataEnv().API_URL}/user/setclient`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const deletetUserClient = (payload) => {
  return axios.post(`${dataEnv().API_URL}/user/client/delete`, payload)
}

/**
* Function to get token
* @param {Object} payload
* @return AxiosPromise
*/
export const getUserClient = (id, client) => {
  return axios.get(`${dataEnv().API_URL}/userlist-client/${id}/type/${client}/list`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const validateEmailExistToken = (email) => {
  const payload = {
    email: email
  }
  return axios.post(`${dataEnv().API_URL}/user/validate-email/email`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const acceptedTC = () => {
  const payload = {
    acceptedTC: 1
  }
  return axios.post(`${dataEnv().API_URL}/user/accepted-tc`, payload)
}
