<template>
  <agencia-layout>
    <router-view></router-view>
  </agencia-layout>
</template>

<script>
import AgenciaLayout from '@/layouts/AgenciaLayout'
export default {
  name: 'DashboardAgencia',
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
  },
  components: {
    AgenciaLayout
  }
}
</script>
