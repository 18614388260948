import axios from 'axios'
import { dataEnv } from '../../config/index.js'

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getAdvertisers = (id) => {
  return axios.get(`${dataEnv().API_URL}/agency/${id}/advertisers`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getAdvertisersList = (id) => {
  return axios.get(`${dataEnv().API_URL}/agency/${id}/advertisers-list`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const createAdvertiserAgency = (payload) => {
  return axios.post(`${dataEnv().API_URL}/agency/create-advertiser`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getAdvertiserInfo = (id) => {
  return axios.get(`${dataEnv().API_URL}/agency/${id}/info`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const updateAdvertiser = (payload) => {
  return axios.put(`${dataEnv().API_URL}/agency/update/name`, payload)
}
