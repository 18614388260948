<template>
  <div class="register-back-finish">
    <register-view v-if="validateRegister" :changeStatus="changeStatus"></register-view>
    <message-finish v-if="!validateRegister" :emailData="emailData"></message-finish>
  </div>
</template>

<script>
import RegisterView from '@/components/register/RegisterView'
import MessageFinish from '@/components/register/MessageFinish'
import { registerAgency, registerAdvertiser, registerGroupRadio } from '@/services/register'

export default {
  name: 'StatusRegister',
  props: {
  },
  data () {
    return {
      validateRegister: true,
      emailData: ''
    }
  },
  created () {
  },
  methods: {
    changeStatus (value, data, type) {
      this.emailData = data.email
      const structureObject = {
        fullname: data.name,
        email: data.email,
        phone: data.number,
        pass: data.repitepassword,
        type: type
      }
      if (type === 1) {
        registerGroupRadio(structureObject).then((response) => {
        })
      } else if (type === 2) {
        registerAgency(structureObject).then((response) => {
        })
      } else if (type === 3) {
        registerAdvertiser(structureObject).then((response) => {
        })
      }
      this.validateRegister = value
    }
  },
  mounted () {
  },
  components: {
    RegisterView: RegisterView,
    MessageFinish: MessageFinish
  }
}
</script>
