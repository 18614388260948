import axios from 'axios'
import { dataEnv } from '../../config/index.js'

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const createAdvertiser = (payload) => {
  return axios.post(`${dataEnv().API_URL}/advertiser/account`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const createAccount = (payload) => {
  return axios.post(`${dataEnv().API_URL}/advertiser/account`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const updateAccount = (payload) => {
  return axios.put(`${dataEnv().API_URL}/advertiser/account`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const createBussinesUnit = (payload) => {
  return axios.post(`${dataEnv().API_URL}/advertiser/business-unit`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getBussinesUnit = (id) => {
  return axios.get(`${dataEnv().API_URL}/advertiser/bussines-unit/${id}`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getAccounts = (id) => {
  return axios.get(`${dataEnv().API_URL}/advertiser/${id}/accounts`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getAdvertisersAccount = (id) => {
  return axios.get(`${dataEnv().API_URL}/advertiser/${id}`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getAccountInfo = (id) => {
  return axios.get(`${dataEnv().API_URL}/advertiser/${id}/info`)
}
