<template>
  <div class="row m-0">
    <div>
      <div class="create-data-title text-start">Define tu audiencia</div>
      <div class="pl-2 pr-2">
        <div class="create-data-subtitle mt-4 text-start">¿A que audiencia esta dirigida tu emisora?</div>
      </div>
      <div class="create-data-subtitle mt-4 text-start" style="border: 0px;">Género</div>
      <div class="d-flex flex-row bd-highlight">
        <div class="create-data-checkbox-text">
          <input type="checkbox" id="hombre" name="hombre" @click="sdata.audience.gender.isMale ? setGender(false, true) : setGender(true, true)" :checked="sdata.audience.gender.isMale">
          <label class="register-token-right-subtitle ml-3" for="hombre">Hombre</label>
        </div>
        <div class="create-data-checkbox-text">
          <input type="checkbox" id="mujer" name="mujer" @click="sdata.audience.gender.isFemale ? setGender(false, false) : setGender(true, false)" :checked="sdata.audience.gender.isFemale">
          <label class="register-token-right-subtitle ml-3" for="mujer">Mujer</label>
        </div>
      </div>
      <div class="create-data-subtitle mt-5 text-start" style="border: 0px;">Edad</div>
      <div class="d-flex flex-row bd-highlight">
        <div class="create-data-checkbox-text">
          <input type="checkbox" id="anos8-12" name="anos8-12" @click="sdata.audience.age[0] === 1 ? changeAudience(0, 0, 'age') : changeAudience(1, 0, 'age')" :checked="sdata.audience.age[0] === 1 ? true : false">
          <label class="register-token-right-subtitle ml-3" for="anos8-12">8-12 años</label>
        </div>
        <div class="create-data-checkbox-text">
          <input type="checkbox" id="anos25-34" name="anos25-34" @click="sdata.audience.age[1] === 1 ? changeAudience(0, 1, 'age') : changeAudience(1, 1, 'age')" :checked="sdata.audience.age[1] === 1 ? true : false">
          <label class="register-token-right-subtitle ml-3" for="anos25-34">25-34 años</label>
        </div>
        <div class="create-data-checkbox-text">
          <input type="checkbox" id="anos55-64" name="anos55-64" @click="sdata.audience.age[2] === 1 ? changeAudience(0, 2, 'age') : changeAudience(1, 2, 'age')" :checked="sdata.audience.age[2] === 1 ? true : false">
          <label class="register-token-right-subtitle ml-3" for="anos55-64">55-64 años</label>
        </div>
      </div>
      <div class="d-flex flex-row bd-highlight mt-3">
        <div class="create-data-checkbox-text">
          <input type="checkbox" id="anos13-17" name="anos13-17" @click="sdata.audience.age[3] === 1 ? changeAudience(0, 3, 'age') : changeAudience(1, 3, 'age')" :checked="sdata.audience.age[3] === 1 ? true : false">
          <label class="register-token-right-subtitle ml-3" for="anos13-17">13-17 años</label>
        </div>
        <div class="create-data-checkbox-text">
          <input type="checkbox" id="anos35-44" name="anos35-44" @click="sdata.audience.age[4] === 1 ? changeAudience(0, 4, 'age') : changeAudience(1, 4, 'age')" :checked="sdata.audience.age[4] === 1 ? true : false">
          <label class="register-token-right-subtitle ml-3" for="anos35-44">35-44 años</label>
        </div>
        <div class="create-data-checkbox-text">
          <input type="checkbox" id="anos65" name="anos65" @click="sdata.audience.age[5] === 1 ? changeAudience(0, 5, 'age') : changeAudience(1, 5, 'age')" :checked="sdata.audience.age[5] === 1 ? true : false">
          <label class="register-token-right-subtitle ml-3" for="anos65">65-Más años</label>
        </div>
      </div>
      <div class="d-flex flex-row bd-highlight mt-3">
        <div class="create-data-checkbox-text">
          <input type="checkbox" id="anos18-24" name="anos18-24" @click="sdata.audience.age[6] === 1 ? changeAudience(0, 6, 'age') : changeAudience(1, 6, 'age')" :checked="sdata.audience.age[6] === 1 ? true : false">
          <label class="register-token-right-subtitle ml-3" for="anos18-24">18-24 años</label>
        </div>
        <div class="create-data-checkbox-text">
          <input type="checkbox" id="anos45-54" name="anos45-54" @click="sdata.audience.age[7] === 1 ? changeAudience(0, 7, 'age') : changeAudience(1, 7, 'age')" :checked="sdata.audience.age[7] === 1 ? true : false">
          <label class="register-token-right-subtitle ml-3" for="anos45-54">45-54 años</label>
        </div>
      </div>
      <div class="create-data-subtitle mt-5 text-start" style="border: 0px;">Nivel Socioeconómico</div>
      <div class="d-flex flex-row bd-highlight">
        <div class="create-data-checkbox-text">
          <input type="checkbox" id="nivelab" name="nivelab" @click="sdata.audience.socioeconomic.ab ? setSocioEconomic(false, 1) : setSocioEconomic(true, 1)" :checked="sdata.audience.socioeconomic.ab">
          <label class="register-token-right-subtitle ml-3" for="nivelab">A/B</label>
        </div>
        <div class="create-data-checkbox-text">
          <input type="checkbox" id="nivelc" name="nivelc" @click="sdata.audience.socioeconomic.c ? setSocioEconomic(false, 2) : setSocioEconomic(true, 2)" :checked="sdata.audience.socioeconomic.c">
          <label class="register-token-right-subtitle ml-3" for="nivelc">C</label>
        </div>
        <div class="create-data-checkbox-text">
          <input type="checkbox" id="niveld" name="niveld" @click="sdata.audience.socioeconomic.d ? setSocioEconomic(false, 3) : setSocioEconomic(true, 3)" :checked="sdata.audience.socioeconomic.d">
          <label class="register-token-right-subtitle ml-3" for="niveld">D</label>
        </div>
      </div>
    </div>
    <div class="row m-0 justify-content-center container-inputs-vuestic register-call-to-action-bottom2 text-center mb-0">
      <div>
        <button class="textgreen" @click="addAudience(2)">Completar más tarde</button>
        <button :class="btnEnabled ? 'green ml-4' : 'disabled ml-4'" @click="btnEnabled ? addAudience(3) : ''">Siguiente</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { saveAudience } from '@/services/registerStation'
import router2 from '@/router'

export default {
  name: 'SelectAudience',
  props: {
    setStateSteps: {
      type: Function
    }
  },
  setup (props) {
    const store = useStore()
    const sdata = store.state.station
    function saveAudiences (step) {
      const objs = {
        isFemale: sdata.audience.gender.isFemale,
        isMale: sdata.audience.gender.isMale,
        age: JSON.stringify(sdata.audience.age),
        AB: sdata.audience.socioeconomic.ab,
        C: sdata.audience.socioeconomic.c,
        D: sdata.audience.socioeconomic.d,
        radioStationId: sdata.stationData.idStation,
        step: step
      }
      saveAudience(objs).then((response) => {
      })
    }
    function addAudience (step) {
      store.commit('addAudience', sdata.audience)
      if (step === 3) {
        saveAudiences(step)
        props.setStateSteps(5)
      } else {
        router2.push({ name: 'RadioListView' })
      }
    }
    return {
      sdata,
      addAudience
    }
  },
  data () {
    return {
      gender: false,
      age: false,
      socioeconomic: false
    }
  },
  created () {
  },
  methods: {
    setSocioEconomic (value, type) {
      if (type === 1) {
        this.sdata.audience.socioeconomic.ab = value
      } else if (type === 2) {
        this.sdata.audience.socioeconomic.c = value
      } else if (type === 3) {
        this.sdata.audience.socioeconomic.d = value
      }
    },
    setGender (value, type) {
      if (type) {
        this.sdata.audience.gender.isMale = value
      } else {
        this.sdata.audience.gender.isFemale = value
      }
    },
    changeAudience (value, index, validator) {
      this.gender = false
      this.age = false
      this.socioeconomic = false
      this.sdata.audience[validator][index] = value
    }
  },
  mounted () {
  },
  components: {
  },
  computed: {
    btnEnabled () {
      const vm = this
      this.sdata.audience.age.forEach(function (objs) {
        if (objs === 1) {
          vm.age = true
        }
      })
      if ((this.sdata.audience.gender.isMale || this.sdata.audience.gender.isFemale) && (this.sdata.audience.socioeconomic.ab || this.sdata.audience.socioeconomic.c || this.sdata.audience.socioeconomic.d) && this.age) {
        return true
      }
      return false
    }
  }
}
</script>
