<script setup>
import { ref, computed, defineEmits, reactive, defineProps } from 'vue'
import ImgDown from '@/assets/addEmisora/chevron-down.png'
import { getCitiesbyState, setBilling, updateBilling, detailBilling, saveBilling, getZipCodeInfo, getStates, getCities, getRegimenFiscal, getIndustries, getCountries } from '@/services/billing'
import { useStore } from 'vuex'
const store = useStore()
const sdata = store.state.billignDeatail
const dataFacturations = ref(sdata)
const editing = ref(false)
console.log('Editing State', editing)
const props = defineProps({
  billingId: {
    type: Number,
    default: 0
  },
  clientId: {
    type: Number,
    default: 0
  }
})
console.log('ESTOY EN CREANDO FACTURA', props.clientId)
const city = ref([])
const states = reactive([])
const regimenFiscales = reactive([])
const industries = reactive([])
const countries = reactive([])
const billingId = ref(0)

const getBillingId = () => {
  billingId.value = props.billingId
  detailBilling(billingId.value).then(response => {
    const element = response.data
    sdata.value = {
      razonsocial: element.razonSocial,
      name: element.nombreComercial,
      rfc: element.rfc,
      regimentFiscal: element.regimenFiscal,
      address: element.direccion,
      country: element.countryId,
      city: element.cityId,
      state: element.stateId,
      sector: element.industryId,
      cp: element.zipCode,
      nameBank: element.bancoNombre,
      numberAccount: element.bancoCuenta,
      clabe: element.bancoClabe,
      emailFactura: element.correoFacturacion,
      urlPDF: element.urlConstancia
    }
    allTarifas.value = element.bancoNombre
    dataFacturations.value = sdata.value
    editing.value = true
    console.log('FACTURA dataFacturations', dataFacturations.value)
  })
}
console.log('props.billingId', props.billingId)
if (props.billingId > 0) {
  getBillingId()
} else {
  console.log('FACTURA VACIA', sdata.value)
  const type = localStorage.getItem('typerd')
  const industry = ref('')
  if (type === '1') {
    industry.value = 19
  }
  sdata.value = {
    id: '',
    name: '',
    razonsocial: '',
    sector: industry.value,
    rfc: '',
    regimentFiscal: '',
    address: '',
    country: '',
    city: '',
    state: '',
    cp: '',
    nameBank: '',
    numberAccount: '',
    clabe: '',
    emailFactura: ''
  }
  dataFacturations.value = sdata.value
  console.log('FACTURA dataFacturations', dataFacturations)
}

getCountries().then(async response => {
  const arrayDataState = response.data
  for (const item of arrayDataState) {
    countries.push({
      id: item.countryId,
      text: item.name
    })
  }
})

/* input disabled */
const disableInputs = computed(() => {
  return editing.value || props.billingId > 0
})
/* Validación de cp  */
const isValidZipCode = ref(true)
console.log('isValidZipCode:', isValidZipCode)
const showZipCodeError = ref(false)

const validateZipCode = (v) => {
  // Validación del código postal dígitos
  if (!v || v.length !== 5) {
    isValidZipCode.value = false
    return 'Ingresa un código postal válido'
  } else {
    isValidZipCode.value = true
    return true
  }
}

const zipCodeInfo = () => {
  getZipCodeInfo(dataFacturations.value.cp).then(async response => {
    if (!response.data) {
      showZipCodeError.value = true
      isValidZipCode.value = false
    } else {
      const zipCodeData = response.data // obtener datos del servicio
      dataFacturations.value.city = zipCodeData.cityId
      dataFacturations.value.state = zipCodeData.stateId
      console.log(zipCodeData)
      isValidZipCode.value = true
    }
  }).catch(error => {
    console.log('Error al obtener la información del código postal:', error)
  })
}
const validateAndFetchZipCodeInfo = () => {
  showZipCodeError.value = false // Ocultar mensaje de error al click
  if (validateZipCode(dataFacturations.value.cp) === true) {
    zipCodeInfo()
  }
}

getStates().then(async response => {
  const arrayDataState = response.data
  for (const item of arrayDataState) {
    states.push({
      id: item.stateId,
      text: item.name
    })
  }
})

getIndustries().then(async response => {
  const arrayDataState = response.data
  for (const item of arrayDataState) {
    industries.push({
      id: item.industryId,
      text: item.industry
    })
  }
})

getRegimenFiscal().then(async response => {
  const arrayData = response.data
  for (const item of arrayData) {
    regimenFiscales.push({
      id: item.regimenFiscalId,
      text: item.name
    })
  }
})

getCities().then(async response => {
  const arrayData = response.data
  for (const item of arrayData) {
    city.value.push({
      id: item.cityId,
      text: item.name
    })
  }
})

const allTarifas = ref(false)
const setAllTarifas = (value) => {
  allTarifas.value = value
}
const btnEnabledZC = computed(() => {
  if (dataFacturations.value.cp.length > 4) {
    return true
  }
  return false
})

const btnEnabled = computed(() => {
  if (dataFacturations.value.name !== '' && dataFacturations.value.name !== ' ' && dataFacturations.value.sector !== '' && dataFacturations.value.sector !== ' ' && dataFacturations.value.razonsocial !== '' && dataFacturations.value.razonsocial !== ' ' && dataFacturations.value.rfc !== '' && dataFacturations.value.rfc !== ' ' && dataFacturations.value.regimentFiscal !== '' && dataFacturations.value.regimentFiscal !== ' ' && dataFacturations.value.address !== '' && dataFacturations.value.address !== ' ' && dataFacturations.value.country !== '' && dataFacturations.value.country !== ' ' && dataFacturations.value.city !== '' && dataFacturations.value.city !== ' ' && dataFacturations.value.state !== '' && dataFacturations.value.state !== ' ' && dataFacturations.value.cp !== '' && dataFacturations.value.cp !== ' ') {
    if (allTarifas.value) {
      if (dataFacturations.value.nameBank !== '' && dataFacturations.value.nameBank !== ' ' && dataFacturations.value.numberAccount !== '' && dataFacturations.value.numberAccount !== ' ' && dataFacturations.value.clabe !== '' && dataFacturations.value.clabe !== ' ' && dataFacturations.value.emailFactura !== '' && dataFacturations.value.emailFactura !== ' ') {
        return true
      }
    } else if (!allTarifas.value) {
      return true
    }
  }
  return false
})
const emit = defineEmits(['setStateSteps'])
const setStateSteps2 = (id) => {
  emit('setStateSteps', 2, id)
}
/* editFactura */
const editFactura = ref(false)
console.log('EDITAR FACTURA:', editFactura)
const setFactura = (value) => {
  editFactura.value = value
}
// Input select all
const returAll = (id, type) => {
  if (type === 4) {
    const index = city.value.findIndex(data => data.id === id)
    if (index !== -1) {
      return city.value[index].text
    }
  }
  if (type === 3) {
    const index = states.findIndex(data => data.id === id)
    if (index !== -1) {
      return states[index].text
    }
  }
  if (type === 0) {
    const index = industries.findIndex(data => data.id === id)
    if (index !== -1) {
      return industries[index].text
    }
  }
  if (type === 1) {
    const index = regimenFiscales.findIndex(data => data.id === id)
    if (index !== -1) {
      return regimenFiscales[index].text
    }
  }
  if (type === 2) {
    const index = countries.findIndex(data => data.id === id)
    if (index !== -1) {
      return countries[index].text
    }
  }
}
// input select sector
const showSector = ref(false)
const setSector = (value) => {
  showSector.value = value
}
const validateSector = (id) => {
  if (dataFacturations.value.sector === id) {
    return true
  } else {
    return false
  }
}
const setSectorId = (id) => {
  dataFacturations.value.sector = id
}
// input select regimen fiscal
const showRegimen = ref(false)
const setRegimen = (value) => {
  showRegimen.value = value
}
const validateRegimen = (id) => {
  if (dataFacturations.value.regimentFiscal === id) {
    return true
  } else {
    return false
  }
}
const setRegimenId = (id) => {
  dataFacturations.value.regimentFiscal = id
}
// input select país
const showPais = ref(false)
const setPais = (value) => {
  showPais.value = value
}
const validatePais = (id) => {
  if (dataFacturations.value.country === id) {
    return true
  } else {
    return false
  }
}
const setPaisId = (id) => {
  dataFacturations.value.country = id
}
// input select ciudad
const showCiudad = ref(false)
const setCiudad = (value) => {
  showCiudad.value = value
}
const validateCiudad = (id) => {
  if (dataFacturations.value.city === id) {
    return true
  } else {
    return false
  }
}
const setCiudadId = (id) => {
  dataFacturations.value.city = id
}
// input select state
const showState = ref(false)
const setState = (value) => {
  showState.value = value
}
const validateState = (id) => {
  if (dataFacturations.value.state === id) {
    return true
  } else {
    return false
  }
}
const setStateId = (id) => {
  dataFacturations.value.state = id
  console.log('setStateId', id)
  getCitiesbyState(id).then(response => {
    const arrayData = response.data
    const array2 = []
    for (const item of arrayData) {
      array2.push({
        id: item.cityId,
        text: item.name
      })
    }
    city.value = array2
  })
}
const saveFactura = () => {
  const typeId = parseInt(localStorage.getItem('typerd'))
  const idrd = localStorage.getItem('idrd')
  const objs = {
    id: idrd,
    rfc: dataFacturations.value.rfc,
    regimen: dataFacturations.value.regimentFiscal,
    nombrecomercial: dataFacturations.value.name,
    razonsocial: dataFacturations.value.razonsocial,
    cp: dataFacturations.value.cp,
    direccion: dataFacturations.value.address,
    stateId: dataFacturations.value.state,
    cityId: dataFacturations.value.city,
    countryId: dataFacturations.value.country,
    banco: dataFacturations.value.nameBank,
    CLABE: dataFacturations.value.clabe,
    cuenta: dataFacturations.value.numberAccount,
    correoFacturacion: dataFacturations.value.emailFactura,
    industryId: dataFacturations.value.sector,
    typeId: typeId
  }
  console.log('Envio de DAtos a DB', objs)
  if (billingId.value) {
    sdata.id = billingId.value
    sdata.urlPDF = dataFacturations.value.urlPDF
    objs.billingId = billingId.value
    updateBilling(objs).then((response) => {
      setStateSteps2(sdata.id)
    })
  } else {
    sdata.urlPDF = null
    saveBilling(objs).then((response) => {
      sdata.id = parseInt(response.data)
      billingId.value = parseInt(response.data)
      const array = []
      if (props.clientId > 0) {
        const type = localStorage.getItem('typerd')
        array.push(props.clientId)
        const objsInfo2 = {
          clientId: array,
          billingId: sdata.id,
          type: type
        }
        setBilling(objsInfo2).then((response) => {
          setStateSteps2(sdata.id)
        })
      } else {
        setStateSteps2(sdata.id)
      }
    })
  }
}
const isValidRFC = (txt) => {
  // const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
  const re = /^[A-Za-zñÑ&]{3,4}\d{6}\w{3}$/
  return txt.match(re)
}
</script>

<template>
  <div v-if="!editFactura" style="padding-bottom: 100px;">
    <div v-if="dataFacturations" class="p-2 detail-anunciante-silver" style="background-color: #FFFFFF;">
      <div class="gpr-back-facturacion-title">
        <div class="gpr-float-left p-2 pl-4 gpr-facturation-title">Datos de facturación</div>
      </div>
      <div>
        <div class="pl-5 pr-5">
          <div class="container-inputs-vuestic mt-4 mb-0">
            <label>Nombre comercial*</label>
            <va-input
              v-model="dataFacturations.name"
              placeholder="Ingresa nombre comercial"
              class="mt-1"
            />
          </div>
          <div class="container-inputs-vuestic mt-4 mb-0">
            <label>Razón social*</label>
            <va-input
              v-model="dataFacturations.razonsocial"
              placeholder="Ingresa una razón social"
              class="mt-1"
              :disabled="disableInputs"
            />
          </div>
          <div class="container-inputs-vuestic2 position-relative mt-3 mb-3">
            <label class="mt-3">Sector*</label>
            <div class="create-data-station-input4 create-data-100 create-data-pointer position-relative mt-2">
              <div class="pt-1">
                <div @click="setSector(true)" class="text-start" :style="dataFacturations.sector !== '' ? 'color: #000000;' : ''">{{ dataFacturations.sector !== '' ? returAll(dataFacturations.sector, 0) : 'Selecciona una opción' }}</div>
              </div>
              <div class="create-data-station-w" style="top: 10px;" @click="setSector(true)">
                <img :src="ImgDown" alt="" />
              </div>
            </div>
            <div v-if="showSector" class="booking-campaing-location p-3" @mouseleave="setSector(false)" style="top: 40px; min-width: 350px; height: 200px; overflow-y: auto; z-index: 1000;">
              <div class="booking-campaing-height" style="height: 200px;">
                <div v-for="(item, index) in industries" :key="index" class="create-data-100 mt-3" style="height: auto;">
                  <div class="create-data-checkbox-text create-data-100">
                    <div :class="validateSector(item.id) ? 'booking-campaing-range booking-campaing-range-selected create-data-pointer' : 'booking-campaing-range create-data-pointer'" @click="setSectorId(item.id), setSector(false)">{{ item.text }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-6 gpr-back-info-title create-data-100">
            <div class="container-inputs-vuestic mt-4">
              <label class="mt-3">Sector*</label>
              <div id="ktrmyjknmryrty" class="mt-1">
                <select name="select" v-model="dataFacturations.sector" required class="create-data-style-select2000">
                  <option value="" disabled selected hidden>Selecciona una opción</option>
                  <option v-for="(item, index) in citys" :key="index" :value="item.id">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div> -->
          <div class="d-flex align-items-center">
            <div class="col-7" style="margin-bottom: 18px;">
              <div class="container-inputs-vuestic mt-2">
                <div style="font-size: 18px; font-weight: 700; margin-bottom: 10px;">Información fiscal</div>
                <label class="mt-2 mb-2">Registro Federal de Contribuyentes (RFC)*</label>
                <va-input
                  v-model="dataFacturations.rfc"
                  placeholder="000000000000"
                  oninput="this.value = this.value.toUpperCase()"
                  class="mt-1"
                  :rules="[(v) => (v && (v.length === 13 || v.length === 12) && isValidRFC(v)) || 'Ingresa un RFC válido']"
                  :disabled="disableInputs"
                />
              </div>
            </div>
            <!--div class="col-5 gpr-back-info-title px-3 mt-5">Admitimos números de Registro Federal de Contribuyentes (RFC) de 13 caracteres</div-->
          </div>
          <div class="col-6 gpr-back-info-title create-data-100">
            <div class="container-inputs-vuestic2 position-relative mt-4 mb-3">
              <label class="mt-4">Regimen fiscal*</label>
              <div class="create-data-station-input4 create-data-100 create-data-pointer position-relative mt-2">
                <div class="pt-1">
                  <div @click="setRegimen(true)" class="text-start" :style="dataFacturations.regimentFiscal !== '' ? 'color: #000000;' : ''">{{ dataFacturations.regimentFiscal !== '' ? returAll(dataFacturations.regimentFiscal, 1) : 'Selecciona una opción' }}</div>
                </div>
                <div class="create-data-station-w" style="top: 10px;" @click="setRegimen(true)">
                  <img :src="ImgDown" alt="" />
                </div>
              </div>
              <div v-if="showRegimen" class="booking-campaing-location p-3" @mouseleave="setRegimen(false)" style="top: 40px; min-width: 350px; height: 200px; overflow-y: auto; z-index: 1000;">
                <div class="booking-campaing-height" style="height: 200px;">
                  <div v-for="(item, index) in regimenFiscales" :key="index" class="create-data-100 mt-3" style="height: auto;">
                    <div class="create-data-checkbox-text create-data-100">
                      <div :class="validateRegimen(item.id) ? 'booking-campaing-range booking-campaing-range-selected create-data-pointer' : 'booking-campaing-range create-data-pointer'" @click="setRegimenId(item.id), setRegimen(false)">{{ item.text }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="container-inputs-vuestic mt-2">
              <label class="mt-3">Regimen fiscal*</label>
              <div id="ktrmyjknmryrty" class="mt-1">
                <select name="select" v-model="dataFacturations.regimentFiscal" required class="create-data-style-select2000">
                  <option value="" disabled selected hidden>Selecciona una opción</option>
                  <option v-for="(item, index) in citys" :key="index" :value="item.id">{{ item.text }}</option>
                </select>
              </div>
            </div> -->
          </div>
          <div class="d-flex align-items-center mt-4">
            <div class="col-6">
              <div class="container-inputs-vuestic mt-2">
                <div style="font-size: 18px; font-weight: 700;">Dirección Fiscal</div>
                <label class="mt-2">Dirección *</label>
                <va-input
                  v-model="dataFacturations.address"
                  placeholder="Calle, Núm Int, Núm Ext, Colonia"
                  class="mt-1"
                />
              </div>
            </div>
            <div class="col-6 gpr-back-info-title pl-4 pr-4">
              <div class="container-inputs-vuestic2 position-relative mt-4 mb-3">
                <label class="mt-3">País*</label>
                <div class="create-data-station-input4 create-data-100 create-data-pointer position-relative mt-2">
                  <div class="pt-1">
                    <div @click="setPais(true)" class="text-start" :style="dataFacturations.country !== '' ? 'color: #000000;' : ''">{{ dataFacturations.country !== '' ? returAll(dataFacturations.country, 2) : 'Selecciona una opción' }}</div>
                  </div>
                  <div class="create-data-station-w" style="top: 10px;" @click="setPais(true)">
                    <img :src="ImgDown" alt="" />
                  </div>
                </div>
                <div v-if="showPais" class="booking-campaing-location p-3" @mouseleave="setPais(false)" style="top: 40px; min-width: 350px; height: 200px; overflow-y: auto; z-index: 1000;">
                  <div class="booking-campaing-height" style="height: 200px;">
                    <div v-for="(item, index) in countries" :key="index" class="create-data-100 mt-3" style="height: auto;">
                      <div class="create-data-checkbox-text create-data-100">
                        <div :class="validatePais(item.id) ? 'booking-campaing-range booking-campaing-range-selected create-data-pointer' : 'booking-campaing-range create-data-pointer'" @click="setPaisId(item.id), setPais(false)">{{ item.text }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="container-inputs-vuestic mt-4">
                <label class="mt-3">País*</label>
                <div id="ktrmyjknmryrty" class="mt-1">
                  <select name="select" v-model="dataFacturations.country" required class="create-data-style-select2000">
                    <option value="" disabled selected hidden>Selecciona una país</option>
                    <option v-for="(item, index) in citys" :key="index" :value="item.id">{{ item.text }}</option>
                  </select>
                </div>
              </div> -->
            </div>
          </div>
        <!--   !Codigo postal -->
          <div class="d-flex flex-column gap-2">
            <div class="d-flex align-items-center gap-4">
              <div class="col-6">
                <div class="container-inputs-vuestic">
                  <label class="mt-2">Código Postal*</label>
                  <va-input
                    v-model="dataFacturations.cp"
                    placeholder="Ingresa un código postal"
                    class="mt-1"
                    :rules="[validateZipCode]"
                  />
                </div>
              </div>
              <div class="col-5 d-flex align-items-center ">
                <div class="container-inputs-vuestic d-flex w-auto" style="margin-bottom: 0px; height: 100%;">
                  <button :class="btnEnabledZC ? 'green' : 'disabled'" style="width: auto; height: auto; padding: 13px;" @click="validateAndFetchZipCodeInfo">Valida Código Postal</button>
                </div>
              </div>
            </div>
            <div v-if="showZipCodeError" class="text-danger fw-bold">El Código Postal que ingresaste no es válido</div>
          </div>
          <div class="d-flex align-items-center mt-2">
            <div class="col-6 gpr-back-info-title pr-4">
              <div class="container-inputs-vuestic2 position-relative mb-3">
                <label class="mt-3">Estado/Provincia/Región*</label>
                <div class="create-data-station-input4 create-data-100 create-data-pointer position-relative mt-2">
                  <div class="pt-1">
                    <div @click="setState(true)" class="text-start" :style="dataFacturations.state !== '' ? 'color: #000000;' : ''">{{ dataFacturations.state !== '' ? returAll(dataFacturations.state, 3) : 'Selecciona una opción' }}</div>
                  </div>
                  <div class="create-data-station-w" style="top: 10px;" @click="setState(true)">
                    <img :src="ImgDown" alt="" />
                  </div>
                </div>
                <div v-if="showState" class="booking-campaing-location p-3" @mouseleave="setState(false)" style="top: 40px; min-width: 350px; height: 200px; overflow-y: auto; z-index: 1000;">
                  <div class="booking-campaing-height" style="height: 200px;">
                    <div v-for="(item, index) in states" :key="index" class="create-data-100 mt-3" style="height: auto;">
                      <div class="create-data-checkbox-text create-data-100">
                        <div :class="validateState(item.id) ? 'booking-campaing-range booking-campaing-range-selected create-data-pointer' : 'booking-campaing-range create-data-pointer'" @click="setStateId(item.id), setState(false)">{{ item.text }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="container-inputs-vuestic">
                <label>Estado / Municipio*</label>
                <div id="ktrmyjknmryrty" class="mt-1">
                  <select name="select" v-model="dataFacturations.state" required class="create-data-style-select2000">
                    <option value="" disabled selected hidden>Selecciona una país</option>
                    <option v-for="(item, index) in citys" :key="index" :value="item.id">{{ item.text }}</option>
                  </select>
                </div>
              </div> -->
            </div>
            <div class="col-6">
              <div class="container-inputs-vuestic2 position-relative mb-3">
                <label class="mt-3">Ciudad*</label>
                <div class="create-data-station-input4 create-data-100 create-data-pointer position-relative mt-2">
                  <div class="pt-1">
                    <div @click="setCiudad(true)" class="text-start" :style="dataFacturations.city !== '' ? 'color: #000000;' : ''">{{ dataFacturations.city !== '' ? returAll(dataFacturations.city, 4) : 'Selecciona una opción' }}</div>
                  </div>
                  <div class="create-data-station-w" style="top: 10px;" @click="setCiudad(true)">
                    <img :src="ImgDown" alt="" />
                  </div>
                </div>
                <div v-if="showCiudad" class="booking-campaing-location p-3" @mouseleave="setCiudad(false)" style="top: 40px; min-width: 350px; height: 200px; overflow-y: auto; z-index: 1000;">
                  <div class="booking-campaing-height" style="height: 200px;">
                    <div v-for="(item, index) in city" :key="index" class="create-data-100 mt-3" style="height: auto;">
                      <div class="create-data-checkbox-text create-data-100">
                        <div :class="validateCiudad(item.id) ? 'booking-campaing-range booking-campaing-range-selected create-data-pointer' : 'booking-campaing-range create-data-pointer'" @click="setCiudadId(item.id), setCiudad(false)">{{ item.text }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="container-inputs-vuestic">
                <label>Ciudad*</label>
                <div id="ktrmyjknmryrty" class="mt-1">
                  <select name="select" v-model="dataFacturations.city" required class="create-data-style-select2000">
                    <option value="" disabled selected hidden>Selecciona una país</option>
                    <option v-for="(item, index) in citys" :key="index" :value="item.id">{{ item.text }}</option>
                  </select>
                </div>
              </div> -->
            </div>
          </div>
          <div class="gpr-back-facturacion-title">
            <div class="gpr-float-left p-2 pl-4 gpr-facturation-title">Datos Bancarios (Opcional)</div>
            <div class="gpr-float-right mt-3">
              <div :class="allTarifas ? 'create-data-swicth-disabled create-data-swicth-selected ml-2 create-data-pointer' : 'create-data-swicth-disabled ml-2 create-data-pointer'" @click="allTarifas ? setAllTarifas(false) : setAllTarifas(true)">
                <div class="create-data-switch-no">NO</div>
                <div class="create-data-switch-yes">SI</div>
              </div>
            </div>
          </div>
          <div v-if="allTarifas">
            <div class="d-flex align-items-center create-data-100">
              <div class="col-6">
                <div class="container-inputs-vuestic mt-2">
                  <div style="font-size: 18px; font-weight: 700;">Datos Bancarios</div>
                  <label class="mt-2">Banco</label>
                  <va-input
                    v-model="dataFacturations.nameBank"
                    placeholder="Nombre del banco"
                    class="mt-1"
                  />
                </div>
              </div>
              <div class="col-6 pl-4 pr-4">
                <div class="container-inputs-vuestic mt-4">
                  <label class="mt-3">CLABE</label>
                  <va-input
                    v-model="dataFacturations.clabe"
                    placeholder="Cuenta clabe"
                    class="mt-1"
                    type="number"
                    :rules="[(v) => (v && v.length >= 18 && v.length < 19) || 'Ingresa una CLABE válida de 18 dígitos']"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div class="col-6">
                <div class="container-inputs-vuestic mt-4">
                  <label class="mt-3">Número de cuenta</label>
                  <va-input
                    v-model="dataFacturations.numberAccount"
                    placeholder="Número de cuenta"
                    class="mt-1"
                    type="number"
                    :rules="[(v) => !isNaN(v) || 'Ingresa un Número de cuenta válido']"
                  />
                </div>
              </div>
              <div class="col-6 pl-4 pr-4">
                <div class="container-inputs-vuestic mt-4">
                  <label class="mt-3">Correo de facturación</label>
                  <va-input
                    v-model="dataFacturations.emailFactura"
                    placeholder="Correo de facturación"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-absolute pr-5" style="bottom: 0px; right: 0px; width: 100%; height: 70px; background-color: #FFFFFF;">
      <div class="row m-0 mt-3 justify-content-end container-inputs-vuestic text-end">
        <button :class="btnEnabled ? 'green' : 'disabled'" style="width: 200px;" @click="btnEnabled ? saveFactura() : ''">Siguiente</button>
      </div>
    </div>
  </div>
  <div v-if="editFactura" style="padding-bottom: 100px;">
    <div class="p-2 detail-anunciante-silver" style="background-color: #FFFFFF;">
      <div class="gpr-back-facturacion-title">
        <div class="gpr-float-left p-2 pl-4 gpr-facturation-title">Datos de facturación</div>
        <div class="gpr-float-right p-2 pr-4">
          <div class="row m-0 justify-content-center container-inputs-vuestic">
            <button class="whitetext create-data-100" @click="setFactura(false)" style="width: 180px !important; height: 40px; line-height: 0px;">Editar</button>
          </div>
        </div>
      </div>
      <div class="mt-5 gpr-text-data-contact">
          <div class="gpr-text-title mt-3">Nombre comercial</div>
          <div>{{ dataFacturations.name }}</div>
          <div class="gpr-text-title mt-3">RFC</div>
          <div>{{ dataFacturations.rfc }}</div>
          <div class="gpr-text-title mt-3">Dirección</div>
          <div style="line-height: 20px;">{{ dataFacturations.address }}<br /> Ciudad de México, CDMX</div>
          <div class="gpr-text-title mt-3">Correo</div>
          <div>{{ dataFacturations.emailFactura }}</div>
        </div>
        <div class="gpr-facturation-card-info mt-3 p-3" style="height: 230px;">
          <div class="gpr-text-data-contact">
            <div>Datos Bancarios</div>
            <div class="d-flex">
              <div class="col-7">
                <div class="gpr-text-title mt-3">Banco</div>
                <div>{{ dataFacturations.nameBank }}</div>
              </div>
              <div class="col-5">
                <div class="gpr-text-title mt-3">CLABE</div>
                <div>{{ dataFacturations.clabe }}</div>
              </div>
            </div>
            <div class="gpr-text-title mt-3">Número de cuenta</div>
            <div>{{ dataFacturations.numberAccount }}</div>
            <div class="gpr-text-title mt-3">Correo de facturación</div>
            <div>{{ dataFacturations.emailFactura }}</div>
          </div>
        </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

  .search-container {
    width: 317px;

    .search-icon,
    .trash-icon {
      position: absolute;
      right: 14px;
      top: 6px;
    }
  }
</style>
