<template>
  <div class="row m-0 p-0 register-scroll">
    <va-form ref="form">
      <div class="create-data-title text-start position-fixed" style="background: white; top: 45px; padding-bottom: 5px; padding-right: 20px;">Registro de Cuenta</div>
      <div class="pl-2 pr-2">
        <div class="create-data-subtitle mt-4 text-start">Datos de la Cuenta</div>
      </div>
      <div class="row m-0 justify-content-center mt-3">
        <div v-if="!imgUploads">
          <div class="register-token-right-subtitle text-start">Sube el logo de la cuenta (Opcional)</div>
          <input id="cargararchivo" type="file" class="register-file-opacity" accept="image/png, image/jpeg" @change="showImageFile()">
          <label for="cargararchivo" class="register-file-upload">
            <div class="register-file-upload-white">
              <div class="register-file-container">
                <img :src="ImgFile" alt="" />
                <div class="register-file-title">Formato JPG, PNG <br /> Peso máximo 300kb</div>
                <div class="container-inputs-vuestic text-center mb-0">
                  <div class="border mt-2 mx-auto">Subir imagen</div>
                </div>
                <div class="register-token-right-subtitle2 mt-3" style="color: rgb(189, 189, 189);">Te sugerimos una imagen de 250x250px</div>
              </div>
            </div>
          </label>
        </div>
        <div v-if="imgUploads" class="create-data-upload-img mt-4">
          <div class="position-relative">
            <div id="imgupload"></div>
            <label class="create-data-upload-edits position-absolute" style="left: 45px; bottom: 0px;">
              <div for="cargararchivo" style="width: 100%; height: 100%;;">Editar</div>
              <input id="cargararchivo" type="file" class="register-file-opacity" accept="image/png, image/jpeg" @change="showImageFile()">
            </label>
            <div class="register-token-right-subtitle2 mt-3" style="color: rgb(189, 189, 189);">Te sugerimos una imagen de 250 x 250px</div>
          </div>
        </div>
      </div>
      <div class="row m-0 justify-content-center mt-4 mb-5">
        <div>
          <div class="text-start">Nombre de la Cuenta*</div>
          <div class="container-inputs-vuestic mt-3">
            <va-input
              v-model="sdata.data.name"
              placeholder="Ingresa un nombre del anunciante"
            />
          </div>
        </div>
        <div>
          <div class="text-start">Unidad de Negocio (Opcional)</div>
          <div class="container-inputs-vuestic mt-3">
            <select name="LeaveType" @change="onChange($event)" class="form-control" v-model="sdata.data.bu">
              <option value="0" selected>Selecciona una Unidad de Negocio</option>
              <option v-for="(data, index) in arrayBU" :key="index" :value="data.advertiserId">{{ data.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </va-form>
    <div class="row m-0 justify-content-center container-inputs-vuestic register-call-to-action-bottom2 text-center mb-0">
      <div>
        <button @click="close" class="textgreen">Descartar</button>
        <button :class="btnEnabled ? 'green ml-4' : 'disabled ml-4'" @click="btnEnabled ? formCamps() : ''">Siguiente</button>
      </div>
    </div>
  </div>
</template>

<script>
import ImgClose from '@/assets/complete/cerrar.png'
import ImgLogo from '@/assets/complete/logo.svg'
import ImgData from '@/assets/complete/data.png'
import polygono from '@/assets/register/Polygon1.png'
import frecuencia from '@/assets/complete/Ondas.svg'
import ImgFile from '@/assets/complete/file.png'
import { useStore } from 'vuex'
import { createAccount, getBussinesUnit } from '@/services/advertiser'
import { uploadLogo } from '@/services/register'

export default {
  name: 'LoginView',
  props: {
    setStateSteps: {
      type: Function
    }
  },
  setup (props) {
    const store = useStore()
    const sdata = store.state.userAccount
    function formCamps () {
      store.commit('addAccount', sdata.data)
      console.log(sdata)
      let parentId = localStorage.getItem('idrd')
      if (sdata.data.bu > 0) {
        parentId = sdata.data.bu
      }
      const objs = {
        name: sdata.data.name,
        logo: sdata.data.img,
        BusinessUnit: parentId
      }
      createAccount(objs).then((response) => {
        sdata.data.idAdvertiser = response.data
        props.setStateSteps(2)
      })
    }
    return {
      sdata,
      formCamps
    }
  },
  data () {
    return {
      ImgClose: ImgClose,
      ImgLogo: ImgLogo,
      ImgData: ImgData,
      polygono: polygono,
      frecuencia: frecuencia,
      ImgFile: ImgFile,
      validation: null,
      imgUploads: false,
      arrayBU: []
    }
  },
  created () {
    const idrd = localStorage.getItem('idrd')
    getBussinesUnit(idrd).then(response => {
      this.arrayBU = response.data
      console.log(response.data)
    })
  },
  methods: {
    onChange (event) {
      console.log(event.target.value)
    },
    showImageFile () {
      const archivo = document.getElementById('cargararchivo').files[0]
      const reader = new FileReader()
      this.sdata.data.img = ''
      if (archivo) {
        reader.readAsDataURL(archivo)
        const idrd = localStorage.getItem('idrd')
        const typerd = localStorage.getItem('typerd')
        const imagefile = document.getElementById('cargararchivo').files[0]
        const formData = new FormData()
        formData.append('file', imagefile)
        formData.append('client', idrd)
        formData.append('id', idrd)
        formData.append('type', typerd)
        const vm = this
        this.imgUploads = true
        reader.onloadend = function () {
          document.getElementById('imgupload').setAttribute('style', 'background: url(' + reader.result + ') center center/cover no-repeat #FFFFFF;')
        }
        uploadLogo(formData).then((response) => {
          if (response) {
            vm.sdata.data.img = response.data.location
          }
        }).catch((e) => { })
      }
    },
    close () {
      window.location.href = '/'
    }
  },
  mounted () {
    if (this.sdata.data.img !== '') {
      this.imgUploads = true
      const vm = this
      setTimeout(function () {
        document.getElementById('imgupload').setAttribute('style', 'background: url(' + vm.sdata.data.img + ') center center/cover no-repeat #FFFFFF;')
      }, 500)
    }
  },
  components: {
  },
  computed: {
    btnEnabled () {
      if (this.sdata.data.name !== '') {
        return true
      } return false
    }
  }
}
</script>
<style>
.row > * {
  flex-shrink: 1;
}
/* Agrega un scroll vertical en pantallas pequeñas */
.register-scroll {
  overflow-y: auto; /* Agrega scroll vertical */
  max-height: 80vh; /* Altura máxima para el scroll */
}

/* Define el ancho de la barra de scroll */
::-webkit-scrollbar {
  width: 7px;
}

/* Estilos de la barra de scroll */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

</style>
