import axios from 'axios'
import { dataEnv } from '../../config/index.js'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()
/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getLogin = (payload) => {
  return axios.post(`${dataEnv().API_URL}/login`, payload)
}

/**
 * Function to set token for authentication in localStorage
 * @param {String} token
 * @return void
 */
export const setLogin = (token, time) => {
  cookies.set('token', token, time)
}

/**
 * Function to set token for authentication in localStorage
 * @param {String} token
 * @return void
 */
export const setPassword = (payload) => {
  return axios.put(`${dataEnv().API_URL}/user/setpassword`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const recoveryPassword = (email) => {
  return axios.get(`${dataEnv().API_URL}/user/recoverypwd/${email}`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const tokenValido = (token) => {
  return axios.get(`${dataEnv().API_URL}/user/validatetoken/${token}`)
}

/**
 * Function to know if the user is authenticated
 * @return Boolean
 */
export const isAuthenticated = () => {
  const token = cookies.get('token')
  return (token)
}
