import axios from 'axios'
import { dataEnv } from '../../config/index.js'

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const saveBilling = (payload) => {
  return axios.post(`${dataEnv().API_URL}/billing`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const updateBilling = (payload) => {
  return axios.put(`${dataEnv().API_URL}/billing/update`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const uploadPdf = (payload) => {
  return axios.post(`${dataEnv().API_URL}/upload/pdf`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const updateConstancia = (payload) => {
  return axios.put(`${dataEnv().API_URL}/billing/update/constancia`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const setBilling = (payload) => {
  return axios.put(`${dataEnv().API_URL}/billing/assign`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const detailBilling = (id) => {
  return axios.get(`${dataEnv().API_URL}/billing/${id}/info`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const deleteBilling = (payload) => {
  return axios.put(`${dataEnv().API_URL}/billing/delete`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getListadoBilling = (id, type) => {
  return axios.get(`${dataEnv().API_URL}/billings/${id}/type/${type}`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getListBillings = (id, type) => {
  return axios.get(`${dataEnv().API_URL}/billings/${id}/type/${type}`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getRadiosWithoutBilling = (id) => {
  return axios.get(`${dataEnv().API_URL}/radio-group/${id}/radio-billing`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getZipCodeInfo = (zc) => {
  return axios.get(`${dataEnv().API_URL}/zip-code/info/${zc}`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getCities = () => {
  return axios.get(`${dataEnv().API_URL}/cities`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getCitiesbyState = (state) => {
  return axios.get(`${dataEnv().API_URL}/cities/state/${state}`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getStates = () => {
  return axios.get(`${dataEnv().API_URL}/states`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getRegimenFiscal = () => {
  return axios.get(`${dataEnv().API_URL}/regimen-fiscal`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getIndustries = () => {
  return axios.get(`${dataEnv().API_URL}/industries`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getCountries = () => {
  return axios.get(`${dataEnv().API_URL}/countries`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getAdvertiserWithoutBilling = (id, type) => {
  return axios.get(`${dataEnv().API_URL}/clients-billing/${id}/${type}`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getCountBilling = (id, type) => {
  return axios.get(`${dataEnv().API_URL}/has-billings/${id}/type/${type}`)
}
