import axios from 'axios'
import { dataEnv } from '../../config/index.js'

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getInfoRadioAcr = (acronym, banda) => {
  return axios.get(`${dataEnv().API_URL}/radio-stations/${acronym}/acronym/${banda}`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const deleteRadio = (payload) => {
  return axios.put(`${dataEnv().API_URL}/radio-stations/delete`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getRadioStation = (id) => {
  return axios.get(`${dataEnv().API_URL}/radio-stations/${id}/info`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const findStation = (payload) => {
  return axios.put(`${dataEnv().API_URL}/acronym/exist`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getRadioStationAcronym = (acronym) => {
  return axios.get(`${dataEnv().API_URL}/radio-stations-acronym/${acronym}/info`)
}

export const getLocations = () => {
  return axios.get(`${dataEnv().API_URL}/locations/list-radio`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getCitiesbyState = (state) => {
  return axios.get(`${dataEnv().API_URL}/locations/state/${state}`)
}
