<template>
  <anunciante-layout>
    <router-view></router-view>
  </anunciante-layout>
</template>

<script>
import AnuncianteLayout from '@/layouts/AnuncianteLayout'
export default {
  name: 'DashboardAnunciante',
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
  },
  components: {
    AnuncianteLayout
  }
}
</script>
