<script setup>
import { reactive, ref } from 'vue'
import AvatarIcon from '../icons/AvatarIcon.vue'
import AvatarIconInitials from '../icons/AvatarIconInitials.vue'
import user from '@/assets/user.png'
import { userRole } from '@/services/radioGruop'
import { useCookies } from 'vue3-cookies'
import { useRoute } from 'vue-router'

const route = useRoute()

const { cookies } = useCookies()

const routeRadioList = '/radio/list'
const routeRadioGroupProfile = '/radio/profile'
const routeUserRadioGroupProfile = '/radio/profile/user'

const routeAnuncianteList = '/anunciante/list'
const routeAnuncianteProfile = '/anunciante/profile'
const routeAnuncianteUserProfile = '/anunciante/profile/user'
/* Aunciante o Grupo de Radio */
const type = localStorage.getItem('typerd')
const isRadioUser = type === '1'
const isAnuncianteUser = parseInt(type) > 1

const userRoleInfo = reactive([])
const id = localStorage.getItem('idrd')
userRole(id, type).then(response => {
  const role = response.data.role
  console.log('role', role)
  userRoleInfo.push({
    nivel: role.displayName
  })
})

const hiddenLogo = route.path !== '/radio/register' || route.path !== '/anunciante/register'

const logout = () => {
  if (cookies) {
    cookies.remove('token')
    location.reload('/login') // Redireccionar la página después del logout
  }
}

localStorage.setItem('showvalue', false)
/* bring user data */
const storedUsername = ref('')
if (localStorage.getItem('name')) {
  storedUsername.value = localStorage.getItem('name')
}
const storedEmail = ref('')
if (localStorage.getItem('email')) {
  storedEmail.value = localStorage.getItem('email')
}

const defaultLogo = user
const storedLogo = ref('')
if (localStorage.getItem('logo') !== 'null') {
  storedLogo.value = localStorage.getItem('logo')
}
if (!storedLogo.value) {
  storedLogo.value = defaultLogo
}

const storeClientName = ref('')
if (localStorage.getItem('clientName')) {
  storeClientName.value = localStorage.getItem('clientName')
}
const rdspe = localStorage.getItem('rdspe') === 'A'

</script>
<template>
    <div class="btn-group dropdown-center">
        <button class=" button-config mx-4" type="button">
            <div class="profile-info">
                <p class="profile-info-name fw-medium text-capitalize">{{ storedUsername }}</p> <!-- Nombre -->
                <p v-if="userRoleInfo" class="profile-info-position">{{ userRoleInfo.length > 0 ? userRoleInfo[0].nivel : '' }}</p> <!-- {AQUI VA EL ROL } -->
            </div>
        </button>
        <button type="button" class="btn btn-lg dropdown-toggle dropdown-toggle-split button-config" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="visually-hidden">Toggle Dropdown</span>
        </button>
        <ul v-if="isRadioUser" class="dropdown-menu dropdown-menu-dark ul-dropdown dropdown-center">
            <a :href="routeUserRadioGroupProfile" class="link-user-profile">
              <!-- *TRAER FOTO DEL USUARIO -->
              <li class="d-flex justify-content-center my-4"><AvatarIconInitials :name="storedUsername"/></li>
              <li class="d-flex flex-column text-center">
                  <p class="profile-info-name text-capitalize">{{ storedUsername }}</p>
                  <p class="profile-info-position">{{ storedEmail }}</p>
              </li>
            </a>
            <li v-if="rdspe">
                <a :href="routeRadioList">
                    <button class="create-campaing-button btn d-flex gap-2 align-items-center justify-content-center w-auto m-auto mt-5 text-nowrap">
                        Administrar Cuentas
                    </button>
                </a>
            </li>
            <li v-if="hiddenLogo">
                <a :href="routeRadioGroupProfile">
                    <button class="btn d-flex gap-2 align-items-center justify-content-evenly w-100 m-auto mt-5 text-nowrap bg-black">
                        <AvatarIcon class="avatar-sizing" :logo="storedLogo !== null ? storedLogo : defaultLogo"></AvatarIcon>
                        <div class="d-flex flex-column">
                            <p class="profile-info-name text-white fw-medium">{{ storeClientName }}</p>
                            <p class="profile-info-position text-white">Perfil</p>
                        </div>
                    </button>
                </a>
            </li>
            <li><hr class="dropdown-divider divider"></li>
            <li class="logout"><button class="dropdown-item d-flex justify-content-center text-center" @click="logout">Cerrar Sesión</button></li>
        </ul>
        <ul v-else-if="isAnuncianteUser" class="dropdown-menu dropdown-menu-dark ul-dropdown dropdown-center">
            <a :href="routeAnuncianteUserProfile" class="link-user-profile">
              <!-- *TRAER FOTO DEL USUARIO -->
              <li class="d-flex justify-content-center my-4"><AvatarIconInitials :name="storedUsername"/></li>
              <li class="d-flex flex-column text-center">
                  <p class="profile-info-name text-capitalize">{{ storedUsername }}</p>
                  <p class="profile-info-position">{{ storedEmail }}</p>
              </li>
            </a>
            <li>
                <a :href="routeAnuncianteList">
                    <button class="create-campaing-button btn d-flex gap-2 align-items-center justify-content-center w-auto m-auto mt-5 text-nowrap">
                        Administrar Cuentas
                    </button>
                </a>
            </li>
            <li v-if="hiddenLogo">
                <a :href="routeAnuncianteProfile">
                    <button class="btn d-flex gap-2 align-items-center justify-content-evenly w-100 m-auto mt-5 text-nowrap bg-black">
                        <AvatarIcon class="avatar-sizing" :logo="storedLogo !== null ? storedLogo : defaultLogo"></AvatarIcon>
                        <div class="d-flex flex-column">
                            <p class="profile-info-name text-white fw-medium">{{ storeClientName }}</p>
                            <p class="profile-info-position text-white">Perfil</p>
                        </div>
                    </button>
                </a>
            </li>
            <li><hr class="dropdown-divider divider"></li>
            <li class="logout"><button class="dropdown-item d-flex justify-content-center text-center" @click="logout">Cerrar Sesión</button></li>
        </ul>
    </div>
</template>
<script>

export default {
  name: 'DropdownUser',
  props: {
  },
  data () {
    return {
      data: {
        name: '',
        email: '',
        logo: '',
        clientName: ''
      }
    }
  },
  created () {
  },
  methods: {
  },
  mounted () {
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>
.ul-dropdown {
    background-color: #282828;
    margin: 2rem;
    padding: 2rem;
}
.button-config {
    background-color: transparent;
    border: 0cap;
    width: max-content;
}
.profile {
    display: flex;
    align-items: center;
    gap: 20px;

    .profile-info-name {
        font-family: Helvetica;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
    }

    .profile-info-position {
        font-family: Helvetica;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
    }

    .profile-menu-icon {
        margin-left: 10px;
        cursor: pointer;
    }
}
.create-campaing-button {
    color: white;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    border-radius: 30px;
    background: linear-gradient(175deg, #16D2FB -112.04%, rgba(13, 200, 174, 0.28) 192.5%), linear-gradient(90deg, #1677FB 0%, #0DC8AE 65.62%);
    padding: 8px 16px;

    &:hover {
      background: linear-gradient(175deg, #16D2FB -112.04%, rgba(13, 200, 174, 0.28) 114.97%), linear-gradient(90deg, #1677FB 71.87%, #0DC8AE 100%);
    }
  }
.divider {
   background-color: #686868;
}
.button-info {
    color: white;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    border-radius: 10px;
    padding: 8px 16px;
    &:hover {
        background: gray;
    }
  }
  .avatar-sizing {
    transform: scale(0.7);
  }

.logout {
    font-weight: 400;
    border-radius: 50px;
    &:hover {
      border-radius: 50px;
    }
}
.link-user-profile {
  color: white;
  cursor: pointer;
  transition: all;
  &:hover {
    color: #15c3d6;
    transition: 0.4s ease-in-out;
  }
}

</style>
