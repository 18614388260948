import axios from 'axios'
import { dataEnv } from '../../config/index.js'

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const registerAgency = (payload) => {
  return axios.post(`${dataEnv().API_URL}/agency`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const registerAdvertiser = (payload) => {
  return axios.post(`${dataEnv().API_URL}/advertiser`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const activatedAccount = (token) => {
  return axios.get(`${dataEnv().API_URL}/user/validate-email/${token}`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const validateEmailExist = (email) => {
  return axios.get(`${dataEnv().API_URL}/user/validate/${email}/email`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const registerGroupRadio = (payload) => {
  return axios.post(`${dataEnv().API_URL}/radio-group`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const uploadLogo = (payload) => {
  return axios.post(`${dataEnv().API_URL}/upload/logo`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const uploadNameLogoAgency = (payload) => {
  return axios.put(`${dataEnv().API_URL}/agency/update`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const infoAdvertiser = (id) => {
  return axios.get(`${dataEnv().API_URL}/advertiser/${id}/info`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const infoAgencys = (id) => {
  return axios.get(`${dataEnv().API_URL}/agency/${id}/info`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const infoRadio = (id) => {
  return axios.get(`${dataEnv().API_URL}/radio-group/${id}/info`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const uploadNameLogoRadio = (payload) => {
  return axios.put(`${dataEnv().API_URL}/radio-group/update`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const uploadNameLogoAdvertiser = (payload) => {
  return axios.put(`${dataEnv().API_URL}/advertiser/update`, payload)
}
