import axios from 'axios'
import { dataEnv } from '../../config/index.js'

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getRadioStations = (radioGroupId) => {
  return axios.get(`${dataEnv().API_URL}/radio-group/${radioGroupId}/radio-stations`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const userList = (id, typeid) => {
  return axios.get(`${dataEnv().API_URL}/userlist/${id}/${typeid}`)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const updateUsers = (payload) => {
  return axios.post(`${dataEnv().API_URL}/radio-stations/step/4`, payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const userRole = (id, typeid) => {
  return axios.get(`${dataEnv().API_URL}/user/me/role/${id}/${typeid}`)
}
