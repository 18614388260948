<template>
  <grupo-radio-layout>
    <router-view></router-view>
  </grupo-radio-layout>
</template>

<script>
import GrupoRadioLayout from '@/layouts/GrupoRadioLayout.vue'

export default {
  name: 'DashboardGrupoRadio',
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
  },
  components: {
    GrupoRadioLayout
  }
}
</script>
