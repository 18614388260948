<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path d="M16.9265 17.3443L20.3996 20.7043M19.2796 11.7443C19.2796 16.0742 15.7695 19.5843 11.4396 19.5843C7.1097 19.5843 3.59961 16.0742 3.59961 11.7443C3.59961 7.41438 7.1097 3.9043 11.4396 3.9043C15.7695 3.9043 19.2796 7.41438 19.2796 11.7443Z" stroke="black" stroke-width="2" stroke-linecap="round"/>
    <circle cx="23.0996" cy="22.8047" r="1.5" fill="url(#paint0_linear_359_1246)"/>
    <circle cx="23.0996" cy="22.8047" r="1.5" fill="url(#paint1_linear_359_1246)"/>
    <defs>
      <linearGradient id="paint0_linear_359_1246" x1="21.5996" y1="24.3047" x2="24.5996" y2="24.3047" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1677FB"/>
        <stop offset="0.65625" stop-color="#0DC8AE"/>
      </linearGradient>
      <linearGradient id="paint1_linear_359_1246" x1="22.759" y1="17.5279" x2="24" y2="31.0228" gradientUnits="userSpaceOnUse">
        <stop stop-color="#16D2FB"/>
        <stop offset="0.73306" stop-color="#0DC8AE" stop-opacity="0.28"/>
      </linearGradient>
    </defs>
  </svg>
</template>
