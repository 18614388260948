<template>
  <div class="row m-0">
    <div>
      <div class="create-data-title text-start">Registro de emisora</div>
      <div class="pl-2 pr-2">
        <div class="create-data-subtitle mt-4 text-start">Indica una tarifa única para tu emisora</div>
      </div>
      <div class="pl-2 pr-2">
        <div class="create-data-subtitle mt-4 text-start" style="border: 0px; font-weight: 400;">Define una tarifa única para la emisora <b>{{ sdata.stationData.name }}</b>, esta tarifa será con la que se ofertará y sobre la que se crearán las órdenes de compra para las pautas que soliciten las agencias.</div>
      </div>
      <!--div class="d-flex flex-row bd-highlight align-items-center create-data-inputstyle create-data-inputstyle2 mt-5" style="width: 300px; border: 1px solid #D4D4D4; border-radius: 25px;">
        <div class="create-data-peso ml-3">$</div>
        <input type="text" v-model="sdata.dataTarifa2" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" style="color: #000;" placeholder="00.00">
        <div class="create-data-moneda mr-3">MXN</div>
      </div-->
      <!-- *tarifa por duración de spot -->
      <!--div class="d-flex flex-column gap-3 mt-4">
        <p class="text-start" style="font-size: large;">Tarifa para Spot <span class="fw-bold">10 seg.</span></p>
        <div class="d-flex flex-row bd-highlight align-items-center create-data-inputstyle create-data-inputstyle2" style="width: 300px; border: 1px solid #D4D4D4; border-radius: 25px;">
          <div class="create-data-peso ml-3">$</div>
          <input type="text" v-model="sdata.dataTarifaTen" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" style="color: #000;" placeholder="00.00">
          <div class="create-data-moneda mr-3">MXN</div>
        </div>
      </div-->
      <div class="d-flex flex-column gap-3 mt-4">
        <p class="text-start" style="font-size: large;">Tarifa para Spot <span class="fw-bold">20 seg.</span></p>
        <div class="d-flex flex-row bd-highlight align-items-center create-data-inputstyle create-data-inputstyle2" style="width: 300px; border: 1px solid #D4D4D4; border-radius: 25px;">
          <div class="create-data-peso ml-3">$</div>
          <input type="text" v-model="sdata.dataTarifaTwenty" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" style="color: #000;" placeholder="00.00">
          <div class="create-data-moneda mr-3">MXN</div>
        </div>
      </div>
      <!--div class="d-flex flex-column gap-3 mt-4">
        <p class="text-start" style="font-size: large;">Tarifa para Spot <span class="fw-bold">30 seg.</span></p>
        <div class="d-flex flex-row bd-highlight align-items-center create-data-inputstyle create-data-inputstyle2" style="width: 300px; border: 1px solid #D4D4D4; border-radius: 25px;">
          <div class="create-data-peso ml-3">$</div>
          <input type="text" v-model="sdata.dataTarifaThirty" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" style="color: #000;" placeholder="00.00">
          <div class="create-data-moneda mr-3">MXN</div>
        </div>
      </div-->
    </div>
    <div class="row m-0 justify-content-center container-inputs-vuestic register-call-to-action-bottom2 text-center mb-0">
      <div>
        <button class="textgreen" @click="sendTarifa(3)">Completar más tarde</button>
        <button :class="btnEnabled ? 'green ml-4' : 'disabled ml-4'" @click="btnEnabled ? sendTarifa(4) : ''">Finalizar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { saveTarifa } from '@/services/registerStation'
import router from '@/router'

export default {
  props: {
    setStateSteps: {
      type: Function
    }
  },
  setup (props) {
    const store = useStore()
    const sdata = store.state.station
    return {
      sdata
    }
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    sendTarifa (step) {
      const objs = {
        feeTen: this.sdata.dataTarifaTwenty,
        feeTwenty: this.sdata.dataTarifaTwenty,
        feeThirty: this.sdata.dataTarifaTwenty,
        radioStationId: this.sdata.stationData.idStation,
        step: step
      }
      saveTarifa(objs, step).then((response) => {
        router.push({ name: 'RadioListView' })
      })
    }
  },
  mounted () {
  },
  components: {
  },
  computed: {
    btnEnabled () {
      if (this.sdata.dataTarifaTwenty > 0) {
        return true
      }
      return false
    }
  }
}
</script>
