<template>
  <div class="avatar-icon">
    <span class="initials" v-if="initials">{{ initials }}</span>
  </div>
</template>

<script>
export default {
  name: 'AvatarIconInitials',
  props: {
    name: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    }
  },
  computed: {
    initials () {
      if (!this.name) return ''
      return this.name
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase())
        .join('')
    }
  }
}
</script>
<style lang="scss" scoped>
.avatar-icon {
position: relative;
width: 75px;
height: 75px;
border-radius: 100%;
background: linear-gradient(175deg, #16D2FB -112.04%, rgba(13, 200, 174, 0.28) 192.5%), linear-gradient(90deg, #1677FB 0%, #0DC8AE 65.62%);
  .initials {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.8rem;
    font-weight: bold;
    color: white;
    background-color: black;
    border-radius: 50%;
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.avatar-icon.big {
    width: 85px;
    height: 85px;
    margin-top: -4px;

    .initials {
      width: 82px;
      height: 82px;
    }
  }
</style>
