<script setup>
import { ref, computed, defineEmits } from 'vue'
import ImgFile from '@/assets/complete/file.png'
// import UploadExitosos from '@/assets/createFactura/upload_exitosos.png'
import EyeOpen from '@/assets/createFactura/eye-open.png'
import { uploadPdf, updateConstancia } from '@/services/billing'
import { useStore } from 'vuex'
const store = useStore()
const sdata = store.state.billignDeatail
const imgUploads = ref(false)
const nameFile = ref('')
const btnEnabled = computed(() => {
  if (nameFile.value !== '') {
    return true
  }
  return false
})
const showImageFile = () => {
  const previewPdfs = document.getElementById('previewPdfs')
  const archivo = document.getElementById('cargararchivo').files[0]
  const reader = new FileReader()
  if (archivo) {
    const types = /(\.|\/)(pdf)$/i
    if (types.test(archivo.type) || types.test(archivo.name)) {
      const idrd = localStorage.getItem('idrd')
      const typerd = localStorage.getItem('typerd')
      const formData = new FormData()
      formData.append('file', archivo)
      formData.append('client', idrd)
      formData.append('id', idrd)
      formData.append('type', typerd)
      imgUploads.value = true
      nameFile.value = archivo.name
      reader.addEventListener('load', function () {
        previewPdfs.src = reader.result
      }, false)
      if (archivo) {
        reader.readAsDataURL(archivo)
      }
      uploadPdf(formData).then((response) => {
        if (response) {
          sdata.urlPDF = response.data.location
        }
      }).catch((e) => { })
    }
  }
}
const emit = defineEmits(['setDetailFacturas'])
const setDetailFacturas = () => {
  const objsInfo = {
    urlConstancia: sdata.urlPDF,
    billingId: sdata.id
  }

  updateConstancia(objsInfo).then((response) => {
    emit('setDetailFacturas', true)
  })
}
const showPrevieew = (value) => {
  if (value) {
    document.getElementById('previewPdfs2').style.display = ''
  } else {
    document.getElementById('previewPdfs2').style.display = 'none'
  }
}
const getPdf = () => {
  if (sdata.urlPDF) {
    imgUploads.value = true
    nameFile.value = sdata.urlPDF
    const previewPdfs = document.getElementById('previewPdfs')
    console.log(previewPdfs)
    // previewPdfs.src = sdata.urlPDF
  }
}
getPdf()
</script>

<template>
  <div style="padding-bottom: 100px;">
    <input id="cargararchivo" type="file" class="register-file-opacity" accept="application/pdf" @change="showImageFile()">
    <div class="gpr-back-facturacion-title">
      <div class="gpr-float-left p-2 pl-4 gpr-facturation-title">Sube tus Documentos para validación de tus datos</div>
    </div>
    <div v-if="!imgUploads">
      <label for="cargararchivo" class="register-file-upload">
        <div class="register-file-upload-white">
          <div class="register-file-container">
            <div style="font-weight: 700;">Constancia Fiscal</div>
            <img :src="ImgFile" class="mt-3" alt="" />
            <div class="register-file-title mt-2">Formato PDF <br /> Peso máximo 300kb</div>
            <div class="d-flex justify-content-center container-inputs-vuestic mb-0">
              <div class="border mt-2" style="height: 30px; line-height: 15px;">Subir PDF</div>
            </div>
          </div>
        </div>
      </label>
    </div>
    <div v-if="imgUploads">
      <div class="register-file-upload mt-4" style="height: 110px;">
        <div class="register-file-upload-white p-3">
          <div class="gpr-float-left">
            <div class="gpr-facturation-title">Constancia Fiscal</div>
            <div class="d-flex align-items-center" @click="showPrevieew(true)">
              <!-- <img :src="UploadExitosos" /> -->
              <div class="ml-3 p-2 pl-4 pr-4" style="font-size: 16px; text-decoration-line: underline; border-radius: 30px; background: rgba(0, 0, 0, 0.05);">{{ nameFile }}</div>
              <img :src="EyeOpen" class="ml-3" width="28" height="28" />
            </div>
          </div>
          <div class="gpr-float-right mt-3">
            <div class="d-flex align-items-center">
              <div class="d-flex justify-content-center container-inputs-vuestic mb-0">
                <label for="cargararchivo" class="border create-data-pointer" style="width: 120px; height: 30px; line-height: 15px; margin-top: 35px;">Editar</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-absolute pr-5" style="bottom: 0px; right: 0px; width: 100%; height: 70px; background-color: #FFFFFF;">
    <div class="row m-0 mt-3 justify-content-end container-inputs-vuestic text-end">
      <button :class="btnEnabled ? 'green' : 'disabled'" style="width: 200px;" @click="btnEnabled ? setDetailFacturas() : ''">Guardar y Enviar</button>
    </div>
  </div>
  <div id="previewPdfs2" class="create-data-pointer" style="display: none;">
    <div @click="showPrevieew(false)" class="d-flex justify-content-center detail-anunciante-sidebar-silver">
      <iframe id="previewPdfs" :src="sdata.urlPDF" style="width: 900px; height: calc(100% - 80px); margin: 40px 0px;"></iframe>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .search-container {
    width: 317px;

    .search-icon,
    .trash-icon {
      position: absolute;
      right: 14px;
      top: 6px;
    }
  }
</style>
