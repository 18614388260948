<script setup>
import { defineProps } from 'vue'
import user from '@/assets/user.png'

defineProps({
  size: {
    type: String
  },
  logo: {
    type: String,
    default: ''
  }
})
const defaultLogo = user
</script>

<template>
  <div :class="[size, { 'no-logo': !logo }]" class="avatar-icon d-flex justify-content-center align-items-center">
    <img class="avatar-image" :src="logo || defaultLogo" alt="logo">
  </div>
</template>

<style lang="scss" scoped>
.avatar-icon {
    width: 54px;
    height: 54px;
    border-radius: 100%;
    background: linear-gradient(175deg, #16D2FB -112.04%, rgba(13, 200, 174, 0.28) 192.5%), linear-gradient(90deg, #1677FB 0%, #0DC8AE 65.62%);

    .avatar-image {
      width: 52px;
      height: 52px;
      object-fit: cover;
      border-radius: 100%;
      background: white;
      padding: 3px
    }
  }
  .avatar-icon.small {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background: linear-gradient(175deg, #16D2FB -112.04%, rgba(13, 200, 174, 0.28) 192.5%), linear-gradient(90deg, #1677FB 0%, #0DC8AE 65.62%);

    .avatar-image {
      width: 34px;
      height: 34px;
      object-fit: cover;
      border-radius: 100%;
      background: white;
      padding: 3px
    }
  }
  .avatar-icon.xx-small {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background: linear-gradient(175deg, #16D2FB -112.04%, rgba(13, 200, 174, 0.28) 192.5%), linear-gradient(90deg, #1677FB 0%, #0DC8AE 65.62%);

    .avatar-image {
      width: 23px;
      height: 23px;
      object-fit: cover;
      border-radius: 100%;
      background: white;
      padding: 3px
    }
  }
  .avatar-icon.x-small {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: linear-gradient(175deg, #16D2FB -112.04%, rgba(13, 200, 174, 0.28) 192.5%), linear-gradient(90deg, #1677FB 0%, #0DC8AE 65.62%);

    .avatar-image {
      width: 28px;
      height: 28px;
      object-fit: cover;
      border-radius: 100%;
      background: white;
      padding: 3px
    }
  }

  .avatar-icon.big {
    width: 80px;
    height: 80px;
    margin-top: -4px;

    .avatar-image {
      width: 78px;
      height: 78px;
    }
  }
  .avatar-icon.x-big {
    width: 100px;
    height: 100px;
    margin-top: -4px;

    .avatar-image {
      width: 98px;
      height: 98px;
    }
  }

  .avatar-icon.x-big {
    width: 100px;
    height: 100px;
    margin-top: -4px;

    .avatar-image {
      width: 98px;
      height: 98px;
    }
  }

  .no-logo .avatar-image {
  width: 100%;
  height: 100%;
}
</style>
