<template>
  <main class="main">
    <TheHeader/>
    <slot></slot>
  </main>
</template>

<script>
import TheHeader from '@/components/radio/TheHeader'

export default {
  components: {
    TheHeader
  },
  name: 'DefaultLayout',
  props: {
  },
  created () {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
