<template>
  <div class="row m-0 justify-content-center modal-confirmation-container">
    <div>
      <div class="text-end">
        <img :src="ImgClose" alt="" width="24" height="24" />
      </div>
      <div class="modal-confirmation-title text-center mt-3">¡Bienvenido Radiorama!</div>
      <div class="modal-confirmation-subtitle text-center mt-2">Comienza registrando tus emisoras para aprovechar al máximo tus espacios publicitarios.</div>
      <div class="pl-4 pr-4">
        <div class="modal-confirmation-white mt-5">
          <div class="position-relative">
            <img :src="ImgHeader" alt="">
            <div class="img-header">
              <img :src="ImgEllipe7" alt="" />
            </div>
            <div class="create-account">
              <div class="container-inputs-vuestic text-center">
                <button class="black mt-4" @click="setStateSteps(2)">Registrar emisoras</button>
              </div>
            </div>
          </div>
          <div class="p-4">Registra las emisoras de tu grupo</div>
          <!--ul class="register-file-description pl-5">
            <li>Beneficio 01</li>
            <li>Beneficio 02</li>
            <li>Beneficio 03</li>
          </ul-->
          <div class="container-inputs-vuestic text-center">
            <button class="white mt-4">Administrar Cuentas</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgClose from '@/assets/complete/cerrar.png'
import ImgHeader from '@/assets/addEmisora/Img.png'
import ImgEllipe7 from '@/assets/addEmisora/Ellipse7.png'

export default {
  name: 'ModalConfirmation',
  props: {
    setStateSteps: {
      type: Function
    }
  },
  data () {
    return {
      ImgClose: ImgClose,
      ImgHeader: ImgHeader,
      ImgEllipe7: ImgEllipe7
    }
  },
  created () {
  },
  methods: {
  },
  mounted () {
  },
  components: {
  }
}
</script>
