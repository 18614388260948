<script setup>
import ImgClose from '@/assets/detailAnunciante/Icon-item-border.png'
import IsotipoRd from '@/assets/detailAnunciante/isotipo-RD.png'
import IsotipoRdSelected from '@/assets/detailAnunciante/isotipo-RD-selected.png'
import CreateFactura from '@/components/createFactura/CreateFactura'
import UploadFilePdf from '@/components/createFactura/UploadFilePdf'
import FacturaVinculada from '@/components/createFactura/FacturaVinculada'
import { ref, defineEmits, defineProps } from 'vue'
// import { useRouter } from 'vue-router'

const props = defineProps({
  billingId: {
    type: Number
  },
  clientId: {
    type: Number,
    default: 0
  }
})

// const router = useRouter()
const billingId = ref(null)
const validatorShow = ref(1)
const valor1 = ref(1)
const valor2 = ref(0)
const setValidator = (value) => {
  if (valor1.value === value) {
    validatorShow.value = value
  } else if (valor2.value === value) {
    validatorShow.value = value
  }
}
const setStateSteps = (value, id) => {
  console.log('FACTURA setStateSteps', id)
  billingId.value = id
  validatorShow.value = value
  if (valor1.value === 0) {
    valor1.value = value
  } else if (valor2.value === 0) {
    valor2.value = value
  }
}
const detailFactura = ref(false)
const emit = defineEmits(['setFacturas'])
const setDetailFacturas = (value) => {
  console.log('FACTURA detailFactura', billingId)
  emit('setFacturas', value, billingId)
}

const redirectUser = () => {
  setDetailFacturas()
}
</script>

<template>
  <div style="height: 100%;">
    <div class="bg-image position-absolute"></div>
    <div class="p-3 position-relative" style="height: 100%;">
      <div class="d-flex align-items-center detail-anunciante-close">
        <img :src="ImgClose" class="create-data-pointer" width="48" height="48" @click="setDetailFacturas()" alt="">
        <div class="create-data-title ml-2" style="font-size: 24px;">Facturación</div>
      </div>
      <div class="d-flex mt-3" style="height: calc(100% - 60px);">
        <div class="col-3 pl-3 pr-3 position-relative mr-3" style="height: 100%;">
          <ul class="mt-5 detail-anunciante-ul create-data-pointer">
            <li class="d-flex" @click="setValidator(1)"><img :src="(validatorShow + 1) === 2 ? IsotipoRdSelected : IsotipoRd" width="30" height="30" class="mr-2" alt="" /> Datos de Facturación</li>
            <li class="d-flex mt-3" @click="setValidator(2)"><img :src="(validatorShow + 1) === 3 ? IsotipoRdSelected : IsotipoRd" width="30" height="30" class="mr-2" alt="" />Documentos</li>
            <li v-if="detailFactura" class="d-flex mt-3" @click="setValidator(3)"><img :src="(validatorShow + 1) === 4 ? IsotipoRdSelected : IsotipoRd" width="30" height="30" class="mr-2" alt="" />Anunciantes Vinculados</li>
          </ul>
          <div class="row m-0 justify-content-center container-inputs-vuestic position-fixed" style="left: -20%; bottom: 60px;">
            <button @click="redirectUser()" class="textgreen" style="width: 230px !important; height: 35px; line-height: 0px;">Completar más tarde</button>
          </div>
        </div>
        <div class="col-9 pr-5 ml-5" style="overflow-y: auto;">
          <CreateFactura :clientId="props.clientId" :billingId="props.billingId" v-if="validatorShow === 1" @setStateSteps="setStateSteps"></CreateFactura>
          <UploadFilePdf v-if="validatorShow === 2" @setDetailFacturas="setDetailFacturas"></UploadFilePdf>
          <FacturaVinculada v-if="validatorShow === 3"></FacturaVinculada>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .bg-image {
    top: 0px;
    background: url('../../assets/detailAnunciante/BG-FondoBlanco.png') top left / cover no-repeat transparent;
    width: 100%;
    height: 100%;
    opacity: 0.6;
  }
</style>
