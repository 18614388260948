<template>
  <div class="row m-0 p-0">
    <div class="sidebar-menu register-token-left create-account-left p-4" style="width: 22%;">
      <div class="flex-column gap-3">
          <div class="d-flex justify-content-start w-100">
            <CloseIcon @click="close" class="back-icon-menu" style="width: 20px;"></CloseIcon>
          </div>
          <a href='/radio/campaign-list' class="d-flex align-self-center mt-5 justify-content-center">
            <img :src="ImgLogo" alt="Logo RadioDesk" class="img-fluid mb-5" width="210" height="100"/>
          </a>
        </div>
        <!-- *triangulo -->
          <svg class="position-absolute triangle-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="39"
            viewBox="0 0 32 39" fill="none">
            <path
              d="M29.7369 16.1311C32.195 17.7045 32.195 21.2955 29.7369 22.8689L6.65647 37.6429C3.99405 39.3471 0.500002 37.4351 0.500002 34.2739L0.500003 4.72608C0.500003 1.56493 3.99405 -0.347087 6.65647 1.35714L29.7369 16.1311Z"
              fill="black" />
          </svg>

      <div class="create-data-pointer mt-4 d-flex gap-2 align-items-center">
        <img :src="(stateSteps + 1) === 3 ? ImgDataSelect : ImgData" alt="" />
        <div :class="(stateSteps + 1) === 3 ? 'register-token-left-title steps-texts ml-3' : 'create-account-left-title steps-texts-inactive ml-3'">Datos de Emisora</div>
      </div>
      <div class="create-data-pointer mt-4 d-flex gap-2 align-items-center">
        <img :src="(stateSteps + 1) === 4 ? ImgDataSelect : ImgData" alt="" />
        <div :class="(stateSteps + 1) === 4 ? 'register-token-left-title steps-texts ml-3' : 'create-account-left-title steps-texts-inactive ml-3'">Agregar Usuarios</div>
      </div>
      <div class="create-data-pointer mt-4 d-flex gap-2 align-items-center">
        <img :src="(stateSteps + 1) === 5 ? ImgDataSelect : ImgData" alt="" />
        <div :class="(stateSteps + 1) === 5 ? 'register-token-left-title steps-texts ml-3' : 'create-account-left-title steps-texts-inactive ml-3'">Audiencia</div>
      </div>
      <div class="create-data-pointer mt-4 d-flex gap-2 align-items-center">
        <img :src="(stateSteps + 1) === 6 ? ImgDataSelect : ImgData" alt="" />
        <div :class="(stateSteps + 1) === 6 ? 'register-token-left-title steps-texts ml-3' : 'create-account-left-title steps-texts-inactive ml-3'">Tarifas</div>
      </div>
    </div>
    <div class="register-token-right register-token-right2 pt-0 pb-5 pr-0" style="width: 80%;">
      <!-- <div class="position-relative">
        <div class="triangulo2">
          <img :src="polygono" alt="" />
        </div>
      </div> -->
      <div class="row m-0 justify-content-center">
        <div :style="stateSteps === 2 || stateSteps === 3 || stateSteps === 4 || stateSteps === 5 ? 'width: 100%; max-width: 100%;' : ''">
          <data-anunciante v-if="stateSteps === 2" :setStateSteps="setStateSteps"></data-anunciante>
          <add-users v-if="stateSteps === 3" :setStateSteps="setStateSteps"></add-users>
          <select-audience v-if="stateSteps === 4" :setStateSteps="setStateSteps"></select-audience>
          <tarifas-datos v-if="stateSteps === 5" :setStateSteps="setStateSteps"></tarifas-datos>
        </div>
      </div>
    </div>
    <modal-confirmation v-if="stateSteps === 1" :setStateSteps="setStateSteps"></modal-confirmation>
  </div>
</template>

<script>
import CloseIcon from '@/components/icons/CloseIcon.vue'
import ImgClose from '@/assets/complete/cerrar.png'
import ImgLogo from '@/assets/complete/logo.svg'
import ImgData from '@/assets/addEmisora/isotipo-RD.png'
import polygono from '@/assets/register/Polygon1.png'
import ImgDataSelect from '@/assets/complete/data.png'
import ModalConfirmation from '@/components/addEmisora/ModalConfirmation'
import DataAnunciante from '@/components/addEmisora/DataAnunciante'
import AddUsers from '@/components/addEmisora/AddUsers'
import SelectAudience from '@/components/addEmisora/SelectAudience'
import TarifasDatos from '@/components/addEmisora/TarifasDatos2'
import { StyleMusic, radioStationProfile } from '@/services/registerStation'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  name: 'StatusAddEmisora',
  props: {
  },
  setup (props) {
    const store = useStore()
    const sdata = store.state.station
    return {
      sdata
    }
  },
  data () {
    return {
      ImgClose: ImgClose,
      ImgLogo: ImgLogo,
      ImgData: ImgData,
      ImgDataSelect: ImgDataSelect,
      polygono: polygono,
      data: {
        name: ''
      },
      stateSteps: 0,
      isEdit: false,
      valor1: 0,
      valor2: 0,
      valor3: 0,
      valor4: 0,
      listStyles: []
    }
  },
  created () {
    this.StyleMusics()
    const router = useRoute()
    if (router.params.id !== undefined) {
      this.isEdit = true
    }
  },
  methods: {
    getNameStation (id) {
      const index = this.listStyles.findIndex(obj => obj.musicStyleId === parseInt(id))
      if (index !== -1) {
        return this.listStyles[index].name
      }
      return ''
    },
    StyleMusics () {
      const router = useRoute()
      StyleMusic().then((response) => {
        this.listStyles = []
        this.listStyles = response.data.resources
        if (router.params.id !== undefined) {
          this.radioStationProfiles(router.params.id)
        } else {
          this.stateSteps = 2
        }
      })
    },
    radioStationProfiles (id) {
      const vm = this
      radioStationProfile(id).then((response) => {
        const dataStationProfile = response.data
        this.sdata.stationData.name = dataStationProfile.name
        if (dataStationProfile.location === null || dataStationProfile.location === 0) {
          this.sdata.stationData.city = ''
        } else {
          this.sdata.stationData.city = dataStationProfile.location
        }
        this.sdata.stationData.img = dataStationProfile.urlLogo
        this.sdata.stationData.radioChecked = dataStationProfile.radioFrequency
        this.sdata.stationData.fm.frecuencia = dataStationProfile.fMstation
        this.sdata.stationData.fm.cobertura = dataStationProfile.fMcoverage
        this.sdata.stationData.fm.siglas = dataStationProfile.fMacronym
        this.sdata.stationData.am.frecuencia = dataStationProfile.aMstation
        this.sdata.stationData.am.cobertura = dataStationProfile.aMcoverage
        this.sdata.stationData.am.siglas = dataStationProfile.aMacronym
        this.sdata.stationData.styleMusic = []
        if (dataStationProfile.musicalStyle !== null) {
          dataStationProfile.musicalStyle.forEach(function (objs) {
            vm.sdata.stationData.styleMusic.push({ id: objs, name: vm.getNameStation(objs) })
          })
        }
        this.sdata.stationData.description = dataStationProfile.description
        this.sdata.stationData.idStation = dataStationProfile.radioStationId
        this.sdata.audience.gender.isMale = dataStationProfile.isMale
        this.sdata.audience.gender.isFemale = dataStationProfile.isFemale
        if (dataStationProfile.age === null) {
          this.sdata.audience.age = [0, 0, 0, 0, 0, 0, 0, 0]
        } else {
          this.sdata.audience.age = dataStationProfile.age
        }
        this.sdata.audience.socioeconomic.ab = dataStationProfile.ab
        this.sdata.audience.socioeconomic.c = dataStationProfile.c
        this.sdata.audience.socioeconomic.d = dataStationProfile.d
        this.sdata.dataTarifa2 = dataStationProfile.fee
        if (this.isEdit && dataStationProfile.step === 5) {
          vm.stateSteps = 2
        } else {
          if (dataStationProfile.step === 0) {
            vm.stateSteps = 2
          } else if (dataStationProfile.step === 1) {
            vm.stateSteps = 3
          } else if (dataStationProfile.step === 2) {
            vm.stateSteps = 4
          } else if (dataStationProfile.step === 3) {
            vm.stateSteps = 5
          } else if (dataStationProfile.step === 4) {
            vm.stateSteps = 5
          }
        }
      })
    },
    setStateSteps2 (value) {
      if (this.valor1 === value) {
        this.stateSteps = value
      } else if (this.valor2 === value) {
        this.stateSteps = value
      } else if (this.valor3 === value) {
        this.stateSteps = value
      } else if (this.valor4 === value) {
        this.stateSteps = value
      }
    },
    setStateSteps (value) {
      this.stateSteps = value
      if (this.valor1 === 0) {
        this.valor1 = value
      } else if (this.valor2 === 0) {
        this.valor2 = value
      } else if (this.valor3 === 0) {
        this.valor3 = value
      } else if (this.valor4 === 0) {
        this.valor4 = value
      }
    },
    // close
    close () {
      window.location.href = '/'
    }
  },
  mounted () {
  },
  components: {
    ModalConfirmation: ModalConfirmation,
    DataAnunciante: DataAnunciante,
    AddUsers: AddUsers,
    SelectAudience: SelectAudience,
    TarifasDatos: TarifasDatos,
    CloseIcon: CloseIcon
  }
}
</script>
<style>
.sidebar-menu {
  background-color: black;
  border-radius: 0px 0px 20px 0px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: white;
  height: 100vh;
  left: 0;
  top: 0;
  width: 300px;
  min-width: 250px;
  z-index: 2;
}
.triangle-icon {
    right: -20px;
    top: 80px;
  }
.steps-texts {
  font-size: 18px;
}
.steps-texts-inactive {
  color: grey;
  font-size: 16px;
}
</style>
