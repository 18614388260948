<template>
  <div>
    <status-add-emisora></status-add-emisora>
  </div>
</template>

<script>
import StatusAddEmisora from '@/views/Dashboard/addEmisora/StatusAddEmisora'

export default {
  name: 'AgenciaHome',
  props: {
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  },
  mounted () {
  },
  components: {
    StatusAddEmisora: StatusAddEmisora
  }
}
</script>
