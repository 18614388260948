<script setup>
import LogoFinish from '@/assets/register/logo_finish.svg'
import AvatarIcon from '../icons/AvatarIcon.vue'
import DropdownUser from '@/components/agency/DropdownUser.vue'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'

const hiddenLogo = ref(false)
const logo = localStorage.getItem('logo')
console.log('logo', logo.indexOf('https:'))
if (logo.indexOf('https:') > -1) {
  hiddenLogo.value = true
}

// Esconder el logo en Campañas
const route = useRoute()
const shouldHideLogo = computed(() => {
  return route.path === '/agencia/create/campaign'
})

const showValue = ref(true)
const returnShow = () => {
  setTimeout(function () {
    if (localStorage.getItem('showvalue') !== null) {
      showValue.value = localStorage.getItem('showvalue')
    }
  }, 1000)
}
returnShow()

// return
const goBack = () => {
  const type = localStorage.getItem('typerd')
  if (type.toString() === '2') {
    router.push({ name: 'AgencyBookingCampaingView' })
  } else {
    router.push({ name: 'AnuncianteBookingCampaingView' })
  }
}

</script>

<template>
  <header class="header px-5">
    <div v-if="showValue" class="create-data-float-left d-flex">
          <svg @click="goBack" class="back-icon" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_245_15341)">
              <rect x="6" y="6" width="48" height="48" rx="24" fill="white" />
              <path d="M32.3996 34.4541L27.5996 29.6541L32.3996 24.8541" stroke="#282828" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round" />
              <rect x="6.5" y="6.5" width="47" height="47" rx="23.5" stroke="url(#paint0_linear_245_15341)" />
              <rect x="6.5" y="6.5" width="47" height="47" rx="23.5" stroke="url(#paint1_linear_245_15341)" />
            </g>
            <defs>
              <filter id="filter0_d_245_15341" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_245_15341" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_245_15341" result="shape" />
              </filter>
              <linearGradient id="paint0_linear_245_15341" x1="6" y1="54" x2="54" y2="54" gradientUnits="userSpaceOnUse">
                <stop stop-color="#1677FB" />
                <stop offset="0.65625" stop-color="#0DC8AE" />
              </linearGradient>
              <linearGradient id="paint1_linear_245_15341" x1="24.5505" y1="-54.4286" x2="44.4066" y2="161.49"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#16D2FB" />
                <stop offset="0.73306" stop-color="#0DC8AE" stop-opacity="0.28" />
              </linearGradient>
            </defs>
          </svg>
          <a href="/agencia/booking-campaign">
            <img v-if="hiddenLogo && !shouldHideLogo" :logo="logo" class="logo mt-2" loading="lazy" :src="LogoFinish" alt="Radio Desk Logo" style="max-width: 210px;">
          </a>
    </div>
    <div class="profile create-data-float-right">
      <DropdownUser></DropdownUser>
      <!-- dropdown arrow -->
      <!--  <svg class="profile-menu-icon" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24"
        fill="none">
        <path d="M17.7656 9.60039L12.9656 14.4004L8.16563 9.60039" stroke="url(#paint0_linear_316_1054)" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round" />
        <path d="M17.7656 9.60039L12.9656 14.4004L8.16563 9.60039" stroke="url(#paint1_linear_316_1054)" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round" />
        <defs>
          <linearGradient id="paint0_linear_316_1054" x1="17.7656" y1="9.60039" x2="8.16563" y2="9.60039"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#1677FB" />
            <stop offset="0.65625" stop-color="#0DC8AE" />
          </linearGradient>
          <linearGradient id="paint1_linear_316_1054" x1="14.0555" y1="20.4432" x2="13.0564" y2="-1.28528"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#16D2FB" />
            <stop offset="0.73306" stop-color="#0DC8AE" stop-opacity="0.28" />
          </linearGradient>
        </defs>
      </svg> -->
      <div>
        <AvatarIcon v-if="hiddenLogo" :logo="logo" />
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header {
  height: 90px;
  align-items: center;
  background: white;
  left: 0;
  padding: 10px 0;
  // position: fixed;
  top: 0;
  width: 100%;

  .back-icon {
    position: absolute;
    cursor: pointer;
      &:hover{
        transition: transform 0.3s ease-in-out;
        transform: scale(90%);
      }
  }

  .logo {
    margin-left: 60px;
  }

  .profile {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-right: 4rem;

    .profile-info-name {
      font-family: 'Helvetica';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .profile-info-position {
      font-family: Helvetica;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
    }

    .profile-menu-icon {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
</style>
