<script setup>
import { ref } from 'vue'
import ImgDelete from '@/assets/addEmisora/trash-03.png'
import LogoLiverpool from '@/assets/detailCampaing/LogoLiverppol.png'
const ListAdvertiser = [
  {
    id: 1,
    name: 'Liverpool'
  },
  {
    id: 1,
    name: 'Unilever'
  }
]
const advertiser = ref(ListAdvertiser)
</script>
<template>
  <div>
    <div class="gpr-back-facturacion-title">
      <div class="gpr-float-left p-2 pl-4 gpr-facturation-title"><B>Anunciantes Asignados a los Datos de facturación</B></div>
      <div class="gpr-float-right mt-2">
        <div class="d-flex justify-content-center container-inputs-vuestic mb-0">
          <div class="border mt-2" style="width: 240px; height: 35px; font-size: 14px; line-height: 15px;">Asignar Datos a Anunciante</div>
        </div>
      </div>
    </div>
    <div v-for="(item, index) in advertiser" :key="index" class="d-flex detail-camapaing-detail-lists mt-2 mb-3 p-2 pt-1" style="height: 45px; border-radius: 100px; background-color: #F9F9F9;">
      <div class="col-11">
        <div class="d-flex align-items-center">
          <img :src="LogoLiverpool" width="30" height="30" style="border-radius: 50%; border: 1px solid #16D2FB; padding: 3px;" />
          <div class="ml-3" style="height: 40px;">
            <div style="height: 10px; font-size: 10px; color: #000;">Anunciante</div>
            <div class="mt-1" style="height: 10px; font-size: 14px; font-weight: 700; color: #000;">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="col-1 pt-3 pl-4 create-data-pointer">
        <img :src="ImgDelete" @click="deleteEmail(item.id)" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .search-container {
    width: 317px;

    .search-icon,
    .trash-icon {
      position: absolute;
      right: 14px;
      top: 6px;
    }
  }
</style>
