import axios from 'axios'
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

axios.interceptors.request.use((config) => {
  // const token = localStorage.getItem('token')
  const token = cookies.get('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, (err) => {
  return Promise.reject(err)
})

axios.interceptors.response.use((response) => {
  return response
}, (err) => {
  if (err.response.status === 401) {
    localStorage.removeItem('token')
    location.href = '/'
  }
  return Promise.reject(err)
})

/* export default axios */
